var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.ticketList,"items-per-page":20,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.issued_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convert_date(item.issued_at))+" ")]}},{key:"item.spot_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.road[item.spot_id])+" / "+_vm._s(item.spot_id)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.statusColor[_vm.ticketStatus[item.status]]) + "--text"),attrs:{"small":"","color":_vm.statusColor[_vm.ticketStatus[item.status]]}},[_vm._v(" "+_vm._s(_vm.ticketStatus[item.status])+" ")])]}},{key:"item.issued_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.issuedBy[item.issued_by])+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }