<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ticketList"
      :items-per-page="20"
      :loading="loading"
    >

      <!-- issued_at -->
      <template #[`item.issued_at`]="{item}">
        {{ convert_date(item.issued_at) }}
      </template>
      <!-- road -->
      <template #[`item.spot_id`]="{item}">
        {{ road[item.spot_id] }} / {{ item.spot_id }}
      </template>

      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="statusColor[ticketStatus[item.status]]"
          :class="`${statusColor[ticketStatus[item.status]]}--text`"
          class="v-chip-light-bg"
        >
          {{ ticketStatus[item.status] }}
        </v-chip>
      </template>

      <!-- issued_by -->
      <template #[`item.issued_by`]="{item}">
        {{ issuedBy[item.issued_by] }}
      </template>
    </v-data-table>

  </div>
</template>

<script>
// import { mdiPencilOutline, mdiDeleteOutline } from '@mdi/js'
// import axios from 'axios'
// import { format } from 'date-fns'
import { getTicketRecords } from '@/api/tickets'
import { ticketStatus, statusColor, road, issuedBy } from '@/@core/utils/filterKM'

export default {
  data: () => ({
    loading: false,
    page: 1,
    icons: {},
    ticketStatus: ticketStatus(),
    statusColor: statusColor(),
    issuedBy: issuedBy(),
    road: road(),
    headers: [
      { text: '單號', value: 'code' },
      { text: '停車時間', value: 'issued_at' },
      { text: '車號', value: 'plate' },
      { text: '路段 / 格號', value: 'spot_id' },
      { text: '繳費期限', value: 'due_date' },
      { text: '金額', value: 'amount' },
      { text: '狀態', value: 'status' },
      { text: '開單員', value: 'issued_by' },
    ],
    selectOptions: [
      { text: 'Current', value: 1 },
      { text: 'Professional', value: 2 },
      { text: 'Rejected', value: 3 },
      { text: 'Resigned', value: 4 },
      { text: 'Applied', value: 5 },
    ],
    ticketList: [],
    // editedIndex: -1,
    // editedItem: {},
    // defaultItem: {},
  }),

  watch: {},

  created() {
    // this.initialize()
  },
  mounted() {
    this.getTickets()
    // console.log(new Date('2021-08-23T14:23:06.000Z'))
  },
  methods: {
    async getTickets() {
      this.loading = true
      try {
        const res = await getTicketRecords({
          page: this.page,
        })
        this.ticketList = res
        console.log(this.ticketList[0].issued_at)
        console.log('issued_at')
        console.log(this.convert_date(this.ticketList[0].issued_at))
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    convert_date(date) {
      // const UTCTimeObj = new Date(date)
      // return format(UTCTimeObj, 'yyyy-MM-dd kk:mm:ss')
      return date.replace(/.000Z/, '').replace(/T/, ' ')
    },
  },
}
</script>
